@charset "UTF-8";

.top {
    &-mv {
        &-wrapper {
            position: relative;
        }
        &-item {
            .u-hide-sp {
                @include view-at(sp) {
                    display: none !important;
                }
            }
            .u-hide-pc {
                @include view-at(pc) {
                    display: none !important;
                }
            }
        }
        &-text {
            position: absolute;
            top: 15px;
            left: 15px;
            max-width: 50%;
            z-index: 1;

            @include view-at(sp) {
                top: 10px;
                left: 10px;
                max-width: 75%;
            }
        }
        &-floating {
            position: absolute;
            top: 50%;
            left: 15px;
            max-width: 35%;
            z-index: 1;

            @include view-at(sp) {
                top: auto;
                left: 10px;
                bottom: 10px;
                max-width: 40%;
            }

            &-close {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 20px;
                height: 20px;
                background-color: $color-text;

                &::before,
                &::after {
                    content: '';
                    top: 50%;
                    right: 3px;
                    position: absolute;
                    width: 14px;
                    height: 2px;
                    background-color: $color-white;
                }
                &::before {
                    transform: rotate(45deg);
                }
                &::after {
                    transform: rotate(-45deg);
                }
            }
        }
        &-controls {
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 1;

            @include view-at(sp) {
                top: 8px;
                right: 7px;
            }

            &-button {
                display: block;
                width: 100px;
                color: $color-white;
                text-align: center;
                padding: 5px;
                background-color: $color-black-light;
                border: 1px solid $color-white;
                border-radius: 3px;
                cursor: pointer;

                @include view-at(sp) {
                    width: 70px;
                    font-size: rem(12px);
                }

                &.replay {
                    display: none;
                }
            }
        }
    }

    &-act {
        padding: 17px 20px;
        background: linear-gradient(40deg, rgba(213,221,197,1) 0%, rgba(255,255,255,1) 50%, rgba(213,221,197,1) 100%);

        @include view-at(sp) {
            padding: 20px 15px;
        }

        &-list {
            display: flex;
            justify-content: center;
            margin: 0 -10px;

            @include view-at(sp) {
                display: block;
                margin: 0 auto;
            }

            li {
                margin: 0 10px;

                @include view-at(sp) {
                    text-align: center;
                    margin: 0 auto;

                    &:not(:first-child) {
                        margin-top: 10px;
                    }
                }
            }
        }

        &-quality {
            @include view-at(pc) {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin: -10px;
            }

            @include view-at(sp) {
                margin: 0 auto;
            }

            li {
                width: calc(100% / 3);
                padding: 10px;

                @include view-at(sp) {
                    width: 100%;
                    text-align: center;
                    padding: 5px 15px;
                }
    
                &.title {
                    @include view-at(pc) {
                        width: calc(100% - calc(100% / 3));
                        display: flex;
                        align-items: center;
                    }

                    .lead {
                        font-size: rem(30px);
                        line-height: 1.2;

                        @include view-at(tab) {
                            font-size: rem(24px);
                        }

                        .number {
                            color: $color-green-dark;
                            font-size: 150%;
                        }
                        .red {
                            color: $color-red;
                        }
                    }
                    .image {
                        img {
                            max-height: 55px;
                        }
                    }
                }
            }

            &-wrapper {
                width: $contents-middle-width;
                max-width: 100%;
                margin: 0 auto;

                @include view-at(tab) {
                    width: 100%;
                }
            }
        }
    }

    &-banner {
        width: 800px;
        max-width: 100%;
    }

    &-copy {
        font-size: rem(24px);
        line-height: 1.6;
        letter-spacing: 0.12em;

        @include view-at(tab) {
            font-size: rem(20px);
        }
        @include view-at(sp) {
            font-size: rem(16px);
        }

        .green {
            color: $color-green;
        }
        .number {
            font-size: 183%;
        }
        .large {
            font-size: 150%;
        }
    }

    &-title {
        position: relative;
        font-size: rem(36px);
        line-height: 1.6;
        letter-spacing: 0.12em;
        text-align: center;
        padding: 140px 0 30px;
        background-image: url(../images/top/title-bg.png);
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: contain;

        @include view-at(tab) {
            font-size: rem(30px);
        }
        @include view-at(sp) {
            font-size: rem(24px);
            padding: 100px 0 15px;
        }
    }

    &-line {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 1px;
            height: 80px;
            display: block;
            margin: auto;
            background-color: $color-text-light;

            @include view-at(sp) {
                height: 60px;
            }
        }
    }

    &-point {
        display: flex;
        justify-content: center;
        align-items: center;

        @include view-at(sp) {
            flex-direction: column;
        }

        .text {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 300px;
                height: 300px;
                background-image: url(../images/top/point-bg.png);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                transform: translate(-50%, -50%);
                z-index: -1;

                @include view-at(sp) {
                    width: 140px;
                    height: 140px;
                }
            }

            &.left {
                @include view-at(sp) {
                    padding: 20px 0;
                }
            }
            &.right {
                @include view-at(sp) {
                    padding: 50px 0;
                }
            }
        }

        .line {
            margin: 0 30px;

            @include view-at(sp) {
                margin: 20px auto;
            }

            span {
                width: 55px;
                height: 1px;
                display: block;
                background-color: $color-text-light;

                @include view-at(sp) {
                    width: 30px;
                }

                &:nth-child(1) {
                    transform: rotate(45deg);
                }
                &:nth-child(2) {
                    transform: rotate(-45deg);
                }
            }
        }

        &-text {
            color: $color-green-dark;
            font-size: rem(32px);
            line-height: 1.4;
            text-align: center;
            margin-bottom: 0;

            @include view-at(tab) {
                font-size: rem(28px);
            }

            &.location {
                @include view-at(sp) {
                    font-size: rem(24px);
                    margin-left: -15px;
                    margin-right: -15px;
                }
            }
            &.high {
                line-height: 1.75;
            }
            &.small {
                font-size: rem(16px);

                @include view-at(tab) {
                    font-size: rem(14px);
                }
            }
            &.medium {
                font-size: rem(22px);

                @include view-at(tab) {
                    font-size: rem(20px);
                }
            }

            span {
                &.medium {
                    font-size: 131%;
                }
            }

            [data-ruby] {
                position: relative;

                @include view-at(sp) {
                    line-height: 2.5;
                }

                &::before {
                    content: attr(data-ruby);
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -1em;
                    margin: auto;
                    font-size: 0.5em;

                    @include view-at(sp) {
                        bottom: -1.5em;
                    }
                }
            }
        }
    }

    &-panel {
        position: relative;

        @include view-at(sp) {
            padding-top: 0 !important;
        }

        &.energy {
            padding-top: 120px;

            @include view-at(tab) {
                padding-top: 60px;
            }
        }
        &.plan {
            padding-top: 120px;

            @include view-at(tab) {
                padding-top: 60px;
            }
        }
        &.town {
            padding-top: 160px;

            @include view-at(tab) {
                padding-top: 55px;
            }
        }
        &.location {
            padding-top: 175px;

            @include view-at(tab) {
                padding-top: 90px;
            }
        }
        &.position {
            padding-top: 46px;

            @include view-at(tab) {
                padding-top: 30px;
            }
        }

        &-detail {
            width: 70%;
            margin-left: auto;
            box-shadow: 0 0 5px rgba($color-black, 0.5);

            @include view-at(tab) {
                width: 65%;
            }
            @include view-at(sp) {
                width: 95%;
                padding: 70px 20px 40px !important;
            }

            &.energy {
                padding: 60px 10px 70px 90px;

                @include view-at(tab) {
                    padding: 40px 40px 50px 80px;
                }
            }
            &.plan {
                padding: 100px 10px 120px 140px;

                @include view-at(tab) {
                    padding: 70px 40px 80px 110px;
                }
            }
            &.town {
                padding: 80px 10px 55px 170px;

                @include view-at(tab) {
                    padding: 60px 40px 45px 140px;
                }
                @include view-at(sp) {
                    padding-top: 50px !important;
                }
            }
            &.location {
                padding: 70px 10px 70px 140px;

                @include view-at(tab) {
                    padding: 60px 40px 60px 110px;
                }
            }
            &.position {
                padding: 45px 10px 60px 135px;

                @include view-at(tab) {
                    padding: 30px 40px 50px 110px;
                }
            }
        }

        &-text {
            font-size: rem(14px);
            line-height: 2.85;

            @include view-at(tab) {
                line-height: 2.4;
            }
            @include view-at(sp) {
                font-size: rem(12px);
                line-height: 2;
            }
        }

        &-button {
            @include view-at(sp) {
                margin-left: 0 !important;
            }

            &.energy {
                margin-left: 140px;

                @include view-at(tab) {
                    margin-left: 90px;
                }
            }
            &.plan {
                margin-left: 90px;

                @include view-at(tab) {
                    margin-left: 60px;
                }
            }
            &.town {
                margin-left: 60px;
                padding-left: 20px;

                @include view-at(tab) {
                    margin-left: 30px;
                }
            }
            &.location {
                margin-left: 90px;
                padding-left: 20px;

                @include view-at(tab) {
                    margin-left: 60px;
                }
            }
            &.position {
                margin-left: 60px;
                padding-left: 20px;
            }
        }

        &-image {
            position: absolute;
            top: 0;
            left: 0;

            @include view-at(sp) {
                position: relative !important;
                width: 80% !important;
                margin-bottom: -40px;
            }

            &.energy {
                width: 41.6%;

                @include view-at(mdpi) {
                    width: 39.6%;
                }
                @include view-at(tab) {
                    width: 40%;
                }
            }
            &.plan {
                width: 41.6%;

                @include view-at(mdpi) {
                    width: 39.6%;
                }
                @include view-at(tab) {
                    width: 40%;
                }
            }
            &.town {
                width: 46.5%;

                @include view-at(mdpi) {
                    width: 44%;
                }
                @include view-at(sp) {
                    width: 100% !important;
                }
            }
            &.location {
                width: 41.8%;
                padding-left: 12%;

                @include view-at(mdpi) {
                    width: 41%;
                    padding-left: 9%;
                }
                @include view-at(tab) {
                    padding-left: 6%;
                }
                @include view-at(sp) {
                    padding-left: 0;
                }
            }
            &.position {
                width: 42.8%;
                padding-left: 7%;

                @include view-at(mdpi) {
                    width: 42%;
                    padding-left: 5%;
                }
                @include view-at(tab) {
                    padding-left: 3%;
                }
                @include view-at(sp) {
                    padding-left: 0;
                }
            }
        }
    }

    &-location {
        padding-top: 110px;

        &-min {
            width: 900px;
            // width: 700px;
            max-width: 100%;
            margin: auto;

            &-list {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin: -10px;

                @include view-at(sp) {
                    display: block;
                    margin: 0 auto;
                }
            }

            &-item {
                width: calc(100% / 3);
                text-align: center;
                padding: 10px;
                
                @include view-at(sp) {
                    width: 100%;
                    text-align: center;
                    padding: 0;

                    &:not(:first-child) {
                        margin-top: 10px;
                    }
                }

                &.ono {
                    img {
                        max-height: 52px;
                    }
                }
                &.rinkan {
                    img {
                        max-height: 52px;
                    }
                }
                &.higashi {
                    img {
                        max-height: 52px;
                        // max-height: 40px;
                    }
                }
                &.machida {
                    width: 100%;
                    text-align: center;

                    @include view-at(sp) {
                        margin-top: 25px;
                    }

                    img {
                        max-height: 40px;

                        @include view-at(sp) {
                            max-height: 65px;
                        }
                    }
                }
            }
        }
    }

    &-position {
        padding-top: 110px;

        &-title {
            color: $color-green-dark;
            font-size: rem(24px);

            @include view-at(tab) {
                font-size: rem(20px);
            }
            @include view-at(sp) {
                font-size: rem(16px);
                letter-spacing: 0.04em;
            }
        }

        &-list {
            li {
                font-size: rem(20px);
                line-height: 2;
                letter-spacing: 0.04em;
                margin-bottom: 10px;

                @include view-at(tab) {
                    font-size: rem(18px);
                    line-height: 1.8;
                }
                @include view-at(sp) {
                    font-size: rem(14px);
                    line-height: 1.4;
                }

                &.low {
                    line-height: 1.5;

                    @include view-at(sp) {
                        line-height: 1.2;
                    }
                }

                .large {
                    color: $color-green-dark;
                    font-size: 220%;

                    @include view-at(sp) {
                        font-size: 175%;
                    }
                }
            }
        }

        &-table {
            width: 100%;

            th,
            td {
                padding: 20px 10px;
            }
            
            th {
                vertical-align: top;

                @include view-at(sp) {
                    padding: 10px 0;

                    img {
                        max-width: 35px;
                    }
                }
            }

            td {
                @include view-at(sp) {
                    padding: 10px 0 10px 10px;
                }
            }

            &-border {
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 2px;
                    background-image : linear-gradient(to right, $color-text 2px, transparent 2px);
                    background-size: 6px 2px;
                    background-repeat: repeat-x;
                    background-position: left bottom;
                }
            }

            &-text {
                font-size: rem(24px);
                line-height: 1.25;

                @include view-at(sp) {
                    font-size: rem(16px);
                }

                .green {
                    color: $color-green;
                }
                .medium {
                    font-size: 87.5%;
                }
                .small {
                    font-size: 75%;
                }
                .number {
                    font-size: 137.5%;
                }
            }
        }
    }

    &-energy,
    &-plan,
    &-town,
    &-location,
    &-position {
        max-width: 1920px;
        margin-left: auto;
        margin-right: auto;
    }
}