@charset "UTF-8";

.equipment {
    &-subnav {
        display: flex;
        justify-content: center;
        margin: 0 -10px;

        @include view-at(sp) {
            flex-wrap: wrap;
            margin: -5px 0;
        }

        li {
            width: 25%;
            padding: 0 10px;

            @include view-at(sp) {
                width: 100%;
                padding: 5px 0;
            }

            a {
                display: block;
                color: $color-green-dark;
                font-size: rem(16px);
                line-height: 1.5;
                letter-spacing: 0.04em;
                text-align: center;
                padding: 25px 10px;
                background-color: $color-white;
                border: 1px solid $color-green-dark;

                @include view-at(sp) {
                    padding: 9px 2px;
                }

                &[aria-selected="true"] {
                    color: $color-white;
                    background-color: $color-green-dark;
                }
            }
        }

        &-item {
            padding: 0;
            border: none;
        }
    }

    &-title {
        color: $color-white;
        font-size: rem(22px);
        letter-spacing: 0.04em;
        margin-bottom: 20px;
        padding: 14px 5px 14px 50px;
        background-color: #8FAE79;

        @include view-at(sp) {
            text-align: center;
            padding: 14px 5px;
            margin-left: -15px;
            margin-right: -15px;
        }
    }
    &-name {
        font-size: rem(20px);
        line-height: 1.5;
        letter-spacing: 0.04em;
        margin-bottom: 10px;

        &.narrow {
            font-size: rem(18px);
            padding-bottom: 3px;
        }

        .small {
            font-size: 60%;
        }
    }
    &-detail {
        font-size: rem(14px);
        line-height: 1.75;
        letter-spacing: 0.04em;
        margin-bottom: 0;
    }
    &-note {
        font-size: rem(10px);
        line-height: 1.2;
        letter-spacing: 0.04em;
    }
    &-subtitle {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        font-size: rem(18px);
        line-height: 1.2;
        text-align: center;
        margin-bottom: 20px;
        padding: 5px;
        background-color: #E3E9CB;
    }

    &-row {
        margin: -20px -10px !important;
    }
    &-col {
        padding: 20px 10px !important;

        &-kitchen {
            &-large {
                width: 63.4%;

                @include view-at(sp) {
                    width: 100%;
                }
            }
            &-small {
                width: 36.6%;

                @include view-at(sp) {
                    width: 100%;
                }
            }
        }
    }

    &-mt {
        &145 {
            margin-top: 145px;

            @include view-at(sp) {
                margin-top: 75px;
            }
        }
        &125 {
            margin-top: 125px;

            @include view-at(sp) {
                margin-top: 65px;
            }
        }
    }

    &-secom {
        &-smartphone {
            &-bg {
                padding: 40px 50px;
                background-color: #FAF5E6;

                @include view-at(sp) {
                    padding: 20px 15px;
                }
            }
            
            &-detail {
                @include view-at(pc) {
                    width: 68.6%;
                    padding: 20px;
                }
            }
            &-image {
                @include view-at(pc) {
                    width: 31.4%;
                    padding: 20px;
                }

                img {
                    border-radius: 50%;
                }
            }
        }

        &-service {
            &-bg {
                margin: 145px 0;
                padding: 60px 0 100px;
                background-color: #F1F0EC;

                @include view-at(sp) {
                    margin: 100px 0;
                    padding: 45px 0 70px;
                }
            }
            &-list {
                display: flex;

                @include view-at(sp) {
                    flex-wrap: wrap;
                    justify-content: center;
                }
            }
            &-item {
                width: 33%;

                @include view-at(sp) {
                    width: 80%;
                }
            }
            &-border {
                margin: 0 40px;

                @include view-at(sp) {
                    width: 60%;
                    margin: 30px 0;
                }

                span {
                    display: block;
                    width: 1px;
                    height: 100%;
                    background-color: $color-text-light;

                    @include view-at(sp) {
                        width: 100%;
                        height: 1px;
                    }
                }
            }
            &-text {
                font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', '游ゴシック', 'Yu Gothic', '游ゴシック体', 'YuGothic', 'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
                font-size: rem(20px);
                line-height: 1.75;
                letter-spacing: 0.04em;
                margin-bottom: 10px;
                padding-top: 5px;
            }
            &-note {
                font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', '游ゴシック', 'Yu Gothic', '游ゴシック体', 'YuGothic', 'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
                font-size: rem(14px);
                line-height: 1.75;
                letter-spacing: 0.04em;
                padding-bottom: 5px;
            }
        }
    }

    &-afterservice {
        &-border {
            border: 2px solid $color-green-dark;
            border-radius: 25px;
        }
    
        &-inner {
            padding: 0 30px 30px;
    
            @include view-at(sp) {
                padding: 0 20px 20px;
            }
        }
    
        &-title {
            color: $color-white;
            font-weight: $bold;
            text-align: center;
            padding: 10px;
            margin-bottom: 0;
            background-color: $color-green-dark;
            border-radius: 20px 20px 0 0;
        }

        &-h3 {
            font-size: rem(24px);
            line-height: 1.5;
            padding-top: 3px;
            padding-left: 10px;
            margin: 40px auto 15px;
            border-left: 5px solid $color-green-dark;

            @include view-at(sp) {
                font-size: rem(20px);
            }
        }
    }

    // tab03
    &-quality {
        @include view-at(pc) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: -5px;
        }

        @include view-at(sp) {
            margin: 0 auto;
        }

        li {
            width: calc(100% / 3);
            padding: 5px;

            @include view-at(sp) {
                width: 100%;
                text-align: center;
                padding: 5px 15px;
            }

            &.title {
                @include view-at(pc) {
                    width: calc(100% - calc(100% / 3));
                    display: flex;
                    align-items: center;
                }

                .lead {
                    font-size: rem(26px);
                    line-height: 1.2;

                    @include view-at(tab) {
                        font-size: rem(24px);
                    }

                    .number {
                        color: $color-green-dark;
                        font-size: 150%;
                    }
                    .red {
                        color: $color-red;
                    }
                }
                .image {
                    img {
                        max-height: 55px;
                    }
                }
            }
        }

        &-wrapper {
            width: $contents-middle-width;
            max-width: 100%;
            margin: 0 auto;

            @include view-at(tab) {
                width: 100%;
            }
        }

        &-h3 {
            color: $color-green-dark;
            font-size: rem(24px);
            padding-bottom: 10px;
            margin-bottom: 20px;
            border-bottom: 1px solid $color-green;

            @include view-at(sp) {
                font-size: rem(18px);
            }

            .large {
                font-size: 155%;
            }
            .small {
                font-size: 66%;
            }
        }
    }

    &-point {
        display: flex;
        justify-content: center;
        align-items: center;

        @include view-at(sp) {
            flex-direction: column;
        }

        .line {
            margin: 0 30px;

            @include view-at(sp) {
                margin: 25px auto;
            }

            span {
                width: 55px;
                height: 1px;
                display: block;
                background-color: $color-text-light;

                @include view-at(sp) {
                    width: 30px;
                }

                &:nth-child(1) {
                    transform: rotate(45deg);
                }
                &:nth-child(2) {
                    transform: rotate(-45deg);
                }
            }
        }

        &-text {
            font-size: rem(32px);
            line-height: 1.4;
            text-align: center;
            margin-bottom: 0;

            @include view-at(tab) {
                font-size: rem(28px);
            }

            &.high {
                line-height: 1.75;
            }
            &.small {
                font-size: rem(16px);

                @include view-at(tab) {
                    font-size: rem(14px);
                }
            }
            &.medium {
                font-size: rem(22px);

                @include view-at(tab) {
                    font-size: rem(20px);
                }
            }

            span {
                &.medium {
                    font-size: 131%;
                }
            }
        }
    }

    &-zeh {
        border: 1px solid #476B6F;

        &-title {
            color: $color-white;
            line-height: 1.5;
            text-align: center;
            padding: 17px 5px;
            background-color: #476B6F;

            @include view-at(sp) {
                font-size: rem(12px);
                padding: 14px 5px;
            }

            &.high {
                padding: 5px;
            }
        }

        &-main {
            margin: 0 -10px !important;
        }
    }
}