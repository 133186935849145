/* --------------------------
フッター
-------------------------- */

.p-fnav {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  &-list {
    &:not(:first-child) {
      margin-left: 50px;
    }
  }

  &-item {
    font-size: rem(13px);
    margin-bottom: 1.6em;

    >a {
      color: $color-white;
    }

    &--subnav {
      font-size: rem(12px);
    }
  }
}
