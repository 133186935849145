/* --------------------------
エクストラナビ
-------------------------- */

.p-exnav {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    @include view-at(tab) {
        display: none;
    }

    &-item {
        margin-left: 15px;

        &.text {
            display: flex;
            flex-direction: column;

            a {
                color: $color-text;
                font-size: rem(14px);
                line-height: 1;
                letter-spacing: 0.04em;

                &:first-child {
                    margin-bottom: 12px;
                }
            }
        }

        &.button {
            a {
                width: auto;
                padding: 5px 16px;
            }
        }
    }

    &-fixed {
        display: none;

        @include view-at(tab) {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
        li {
            width: calc(100% / 3);

            &:not(:first-child) {
                a {
                    border-left: 1px solid $color-white;
                }
            }
            
            a {
                width: 100%;
                height: 100%;
                display: block;
                font-size: rem(12px);
                color: $color-white;
                text-align: center;
                padding: 18px 5px;
                background-color: #2b2b2b;
                border-top: 1px solid $color-white;
                border-bottom: 1px solid $color-white;

                // &.small {
                //     @include view-at(sp) {
                //         font-size: rem(10px);
                //         padding: 19px 5px;
                //     }
                // }
            }
        }
    }
}