/* --------------------------
ハンバーガーメニューアイコン
-------------------------- */

.c-menu {
    display: none;

    @include view-at(tab) {
        position: absolute;
        right: 0;
        top: 0;
        display: block;
        padding: 0;
        z-index: index($z, menu);
    }

    &-wrapper {
        display: none;

        @include view-at(tab) {
            position: relative;
            display: block;
            width: 30px;
            height: 25px;
        }
    }

    &-trigger,
    &-trigger span {
        display: block;
        transition: all .2s;
        box-sizing: border-box;
    }

    &-trigger {
        position: relative;
        width: 30px;
        height: 25px;

        &-label {
            position: absolute;
            left: 0;
            right: 0;
            top: 18px;
            display: block;
            width: 100%;
            height: auto;
            color: $color-text-light;
            font-size: rem(9px);
            text-align: center;
            letter-spacing: 0.05em;
            margin: auto;
            background-color: transparent;
        }
    }

    &-trigger span {
        position: absolute;
        left: 5px;
        width: 20px;
        height: 1px;
        background-color: $color-text-light;
    }

    &-trigger span:nth-of-type(1) {
        top: 0;
    }

    &-trigger span:nth-of-type(2) {
        top: 6px;
    }

    &-trigger span:nth-of-type(3) {
        top: 12px;
    }

    &-trigger.active span:nth-of-type(1) {
        -webkit-transform: translateY(6px) translateX(1px) rotate(-45deg);
        transform: translateY(6px) translateX(1px) rotate(-45deg);
    }

    &-trigger.active span:nth-of-type(2) {
        opacity: 0;
    }

    &-trigger.active span:nth-of-type(3) {
        -webkit-transform: translateY(-6px) translateX(1px) rotate(45deg);
        transform: translateY(-6px) translateX(1px) rotate(45deg);
    }
}