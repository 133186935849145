/* --------------------------
ヘッダー
-------------------------- */

.p-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 63px;
    background-color: $color-white;
    z-index: 1000;

    @include view-at(tab) {
        height: 48px;
    }

    &-inner {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        max-width: 100%;
        padding: 0 15px;
        margin: 0 auto;

        @include view-at(tab) {
            padding: 0;
        }
    }

    &-logo {
        text-align: left;
        padding: 11px 0;

        @include view-at(tab) {
            padding: 9px 0 9px 10px;
        }

        img {
            width: auto;
            height: 40px;

            @include view-at(tab) {
                height: 30px;
            }
        }
    }

    &-link {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }

    &-menu {
        display: none;

        @include view-at(tab) {
            position: absolute;
            top: 11px;
            right: 20px;
            display: block;
        }

        &-item {
            position: relative;
            width: 20%;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: 1px;
                height: calc(100% - 10px);
                margin: auto;
                background-color: $color-green;
            }

            &:last-child {
                &::before {
                    display: none;
                }
            }

            &.request {
                width: 40%;
            }

            a {
                display: inline-block;
                width: 100%;
                height: 100%;
                color: $color-green;
                font-size: rem(10px);
                line-height: 1;
                text-align: center;
                padding: 9px 0;
            }

            img {
                width: auto;
                max-height: 25px;
                margin-bottom: 4px;
            }
        }
    }
}