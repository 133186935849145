html {
    font-size: 16px;
    overflow-x: hidden;
}

body {
    font-family: 'a-otf-ryumin-pr6n', '游明朝', 'Yu Mincho', '游明朝体', 'YuMincho', 'ヒラギノ明朝 ProN', 'Hiragino Mincho ProN', 'ＭＳ Ｐ明朝', 'MS PMincho', serif;
    font-weight: 400;
    color: $color-text;
    font-size: rem(16px);
    text-align: justify;
    text-justify: inter-ideograph;
}

a {
    color: $color-blue;
    text-decoration: none;
    transition: all .3s;

    &:hover {
        opacity: 0.7;
    }
}

p {
    margin-bottom: 1em;
    font-weight: $normal;
    line-height: 2;
    letter-spacing: 0.08em;
    text-align: justify;
    text-justify: inter-ideograph;

    &:last-child {
        margin-bottom: 0;
    }
}

img {
    width: auto;
    height: auto;
    max-width: 100%;
}

.main {
    padding-top: 136px;

    @include view-at(tab) {
        padding-top: 48px;
    }
}