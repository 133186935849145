@charset "UTF-8";

.concept {
    &-mv {
        position: relative;
        width: 100%;
        background-image: url(../images/concept/mv-bg.jpg);
        background-position: center top;
        background-repeat: no-repeat;
        background-size: contain;

        @include view-at(sp) {
            background-size: auto 75%;
        }

        &-copy {
            padding-top: 330px;
            margin-bottom: 80px;

            @include view-at(mdpi) {
                padding-top: 250px;
            }
            @include view-at(laptop) {
                padding-top: 200px;
            }
            @include view-at(sp) {
                padding-top: 100px;
                margin-bottom: 40px;
            }

            img {
                @include view-at(tab) {
                    max-width: 90%;
                }
            }
        }
        &-lead {
            font-size: rem(42px);
            line-height: 1.76;
            letter-spacing: 0.05em;

            @include view-at(tab) {
                font-size: rem(36px);
            }
            @include view-at(sp) {
                font-size: rem(24px);
            }
        }
        &-text {
            font-size: rem(18px);
            line-height: 2.7;
            letter-spacing: 0.12em;

            @include view-at(tab) {
                font-size: rem(16px);
                line-height: 2.4;
            }
            @include view-at(sp) {
                font-size: rem(12px);
                line-height: 2;
            }
        }
    }

    &-logo {
        position: relative;
        text-align: center;
        padding-top: 120px;

        @include view-at(sp) {
            padding-top: 90px;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 1px;
            height: 80px;
            display: block;
            margin: auto;
            background-color: $color-text-light;

            @include view-at(sp) {
                height: 60px;
            }
        }

        img {
            @include view-at(tab) {
                max-height: 108px;
            }
            @include view-at(sp) {
                max-height: 70px;
            }
        }
    }

    &-text {
        font-size: rem(32px);
        letter-spacing: 0.12em;

        @include view-at(tab) {
            font-size: rem(28px);
        }
        @include view-at(sp) {
            font-size: rem(20px);
        }

        .medium {
            font-size: 112.5%;
        }
        .large {
            font-size: 194%;
        }
        .green {
            color: $color-green-dark;
        }
    }

    &-lh {
        &-25 {
            @include view-at(pc) {
                line-height: 2.5;
            }
        }
    }

    &-link {
        &.disabled {
            pointer-events: none;
        }
    }
}