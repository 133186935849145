@charset "UTF-8";

.terrace {
    &-h2 {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        color: $color-green-dark;
        border-bottom: 1px solid $color-green-dark;

        @include view-at(sp) {
            display: block;
        }

        &--name {
            font-size: rem(28px);
            font-weight: $bold;
            padding-left: 5px;
            padding-bottom: 3px;

            @include view-at(sp) {
                font-size: rem(24px);
                padding-left: 0;
            }
        }
    }

    &-station {
        position: relative;
        padding-left: 1.2em;

        &::before {
            content: '';
            position: absolute;
            top: 5px;
            left: 2px;
            display: inline-block;
            width: 0.7em;
            height: 0.7em;
            background-color: $color-text;

            @include view-at(sp) {
                top: 4px;
            }
        }
    }
}