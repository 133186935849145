@charset "UTF-8";

.model {
    &-h2 {
        &-en {
            position: relative;
            color: $color-green-dark;
            font-size: rem(32px);
            line-height: 1.4;
            letter-spacing: 0.08em;
            text-align: center;

            @include view-at(tab) {
                font-size: rem(28px);
            }
            @include view-at(sp) {
                font-size: rem(26px);
                line-height: 1.22;
            }

            &::before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 1px;
                background: linear-gradient(90deg, transparent 0%, $color-green 25%, $color-green 75%, transparent 100%);
            }
        }

        &-ja {
            color: $color-green-dark;
            font-size: rem(14px);
            line-height: 1.5;
            text-align: center;
        }
    }

    &-type {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;

        @include view-at(sp) {
            margin-bottom: 15px;
        }

        .deco {
            max-width: 60px;

            @include view-at(sp) {
                max-width: 40px;
            }
        }
        .text {
            color: $color-text-light;
            font-size: rem(24px);
            padding: 0 .5em;

            @include view-at(sp) {
                font-size: rem(16px);
            }
        }
    }

    &-caption {
        &-right {
            right: 0;
            bottom: 0;
            color: $color-white;
            text-shadow: none;
            padding: 4px 5px;
            background-color: $color-green-dark;
        }
        &-left {
            left: 0;
            bottom: 0;
            color: $color-white;
            text-shadow: none;
            padding: 4px 5px;
            background-color: $color-green-dark;
        }
    }

    &-main {
        // slick 上書き
        .slick-arrow {
            width: 45px;
            height: 45px;

            @include view-at(laptop) {
                width: 35px;
                height: 35px;
            }
            @include view-at(sp) {
                width: 25px;
                height: 25px;
            }

            &::before {
                content: '';
                display: inline-block;
                width: 45px;
                height: 45px;
                border-top: 3px solid $color-blue;
                border-left: 3px solid $color-blue;

                @include view-at(laptop) {
                    width: 35px;
                    height: 35px;
                }
                @include view-at(sp) {
                    width: 25px;
                    height: 25px;
                }
            }
        }
        .slick-prev {
            left: -45px;
            transform: rotate(-45deg);

            @include view-at(laptop) {
                left: -35px;
            }
            @include view-at(laptop) {
                left: -25px;
            }
        }
        .slick-next {
            right: -45px;
            transform: rotate(135deg);

            @include view-at(laptop) {
                right: -35px;
            }
            @include view-at(laptop) {
                right: -25px;
            }
        }
    }

    &-thumbnail {
        margin: -5px !important;

        &-item {
            position: relative;
            width: calc(100% / 8);
            text-align: center;
            padding: 5px !important;

            @include view-at(sp) {
                width: 25%;
            }

            &::before {
                content: '';
                position: absolute;
                top: 5px;
                left: 5px;
                width: calc(100% - 10px);
                height: calc(100% - 10px);
                background-color: rgba(0, 0, 0, .6);
                transition: all .3s;
            }

            &.thumbnail-current {
                &::before {
                    background-color: transparent;
                }
            }

            &:hover {
                @include view-at(pc) {
                    &::before {
                        background-color: transparent;
                    }
                }
            }

            img {
                display: block;
            }
        }
    }
}