/* --------------------------
見出し
-------------------------- */
.c-h2 {
    color: $color-green-dark;
    font-size: rem(32px);
    line-height: 1.7;
    letter-spacing: 0.08em;
    text-align: center;
    margin-bottom: 60px;

    @include view-at(tab) {
        font-size: rem(28px);
        margin-bottom: 40px;
    }
    @include view-at(sp) {
        font-size: rem(26px);
        line-height: 1.22;
        margin-bottom: 30px;
    }

    &.default {
        color: $color-text;

        @include view-at(sp) {
            line-height: 1.7;
        }
    }
    &.small {
        font-size: rem(18px);

        @include view-at(tab) {
            font-size: rem(14px);
        }
        @include view-at(sp) {
            line-height: 1.7;
        }
    }
}

.c-h3 {
    color: $color-green-dark;
    font-size: rem(24px);
    line-height: 1.7;
    letter-spacing: 0.08em;
    text-align: center;
    margin-bottom: 60px;

    @include view-at(sp) {
        font-size: rem(16px);
        margin-bottom: 30px;
    }

    .small {
        font-size: 75%;
    }
    .large {
        font-size: 175%;
    }
}

.c-h4 {
    font-size: rem(20px);
    line-height: 1.7;
    letter-spacing: 0.04em;
    margin-bottom: 20px;

    @include view-at(sp) {
        font-size: rem(18px);
    }
}

.c-h5 {
    font-size: rem(16px);
    line-height: 1.7;
    letter-spacing: 0.04em;
    margin-bottom: 20px;
}

.c-h6 {
    font-size: rem(14px);
    line-height: 1.7;
    letter-spacing: 0.04em;
    margin-bottom: 20px;
}

.c-h--center-line {
    @include center-line();
    margin-bottom: 5px;
}