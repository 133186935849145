@charset "UTF-8";

$plan-den: #814211;
$plan-closet: #CD5D00;

.plan {
    &-subnav {
        display: flex;
        justify-content: center;
        margin: 0 -5px;

        li {
            min-width: 300px;
            max-width: 100%;
            padding: 0 5px;

            @include view-at(sp) {
                min-width: auto;
                width: 50%;
            }

            a {
                display: block;
                color: $color-green-dark;
                font-size: rem(20px);
                line-height: 1;
                letter-spacing: 0.04em;
                text-align: center;
                padding: 20px;
                background-color: $color-white;
                border: 1px solid $color-green-dark;

                &[aria-selected="true"] {
                    color: $color-white;
                    background-color: $color-green-dark;
                }
            }
        }

        &-item {
            padding: 0;
            border: none;
        }
    }

    &-color {
        &-text {
            &-soho {
                color: $color-green-dark;
            }
            &-den {
                color: $plan-den;
            }
            &-closet {
                color: $plan-closet;
            }
            &-open {
                color: $color-text-light;
            }
        }
        &-bg {
            &-soho {
                background-color: $color-green-dark;
            }
            &-den {
                background-color: $plan-den;
            }
            &-closet {
                background-color: $plan-closet;
            }
            &-open {
                background-color: $color-text-light;
            }
        }
        &-border {
            &-soho {
                border-color: $color-green-dark !important;
            }
            &-den {
                border-color: $plan-den !important;
            }
            &-closet {
                border-color: $plan-closet !important;
            }
            &-open {
                border-color: $color-text-light !important;
            }
        }
    }

    &-point {
        border: 1px solid;

        .title {
            color: $color-white;
            font-size: rem(34px);
            line-height: 1;
            letter-spacing: 0;
            text-align: center;
            padding: 8px;
            margin-bottom: 0;

            &.small {
                font-size: rem(28px);
                padding: 11px 8px;
            }
        }
        .inner {
            padding: 15px 15px 20px;
        }
        .text {
            font-size: rem(18px);
            line-height: 1.5;
            letter-spacing: 0;
            text-align: center;
            margin-bottom: 10px;
        }
    }

    &-row {
        margin: -25px -10px !important;

        & > * {
            padding: 25px 10px !important;
        }
    }

    &-thum {
        .disabled {
            pointer-events: none;
        }
    }

    &-feature {
        &-h2 {
            @include view-at(pc) {
                font-size: rem(42px);
            }
        }

        &-wrapper {
            display: flex;
            margin-bottom: 10px;
        }
        &-title {
            width: 33.3333%;
            color: $color-white;
            font-size: rem(32px);
            text-align: center;
            padding: 9px 5px;

            &.hut {
                font-size: rem(24px);
                padding: 13px 5px;

                @include view-at(sp) {
                    font-size: rem(20px);
                    padding: 15px 5px;
                }
            }
            &.closet {
                font-size: rem(16px);
                padding: 17px 5px;

                @include view-at(sp) {
                    padding: 9px 5px;
                }
            }
            &.kitchen {
                font-size: rem(18px);
                padding: 7px 5px;

                @include view-at(sp) {
                    font-size: rem(16px);
                    padding: 9px 5px;
                }
            }
        }
        &-copy {
            width: 66.6666%;
            font-size: rem(16px);
            line-height: 1.25;
            text-align: center;
            padding: 4px 5px;
            background-color: $color-white;
            border: 1px solid;
        }
        &-text {
            font-size: rem(14px);
        }
    }

    &-detail {
        @include view-at(pc) {
            display: flex;
            justify-content: center;
        }

        &-number {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            color: $color-gray;
            font-size: rem(165px);
            line-height: 0.7;
            text-align: center;
            margin: auto;
            z-index: -1;

            @include view-at(sp) {
                line-height: 1;
            }

            &-wrapper {
                position: relative;
                padding: 55px 0 70px;
                border-bottom: 1px solid $color-text-light;

                @include view-at(sp) {
                    padding: 60px 20px 90px;
                }

                &.high {
                    @include view-at(sp) {
                        padding: 45px 20px 75px;
                    }
                }
            }
        }
        &-feature {
            color: $color-green-dark;
            font-size: rem(24px);
            font-weight: $bold;
            line-height: 1.25;
            text-align: center;
        }

        &-spec {
            padding: 10px 0 10px 45px;

            @include view-at(sp) {
                padding: 15px 10px;
            }
        }
        &-room {
            font-size: rem(24px);
            line-height: 1.4;
            margin-top: 10px;

            @include view-at(sp) {
                text-align: center;
                margin-top: 15px;
            }

            .small {
                font-size: 60%;
            }
        }
        &-area {
            li {
                font-size: rem(14px);
                line-height: 1.5;
                letter-spacing: 0.08em;

                @include view-at(sp) {
                    font-size: rem(12px);
                }

                .large {
                    font-size: 200%;
                }
            }
        }

        &-drawing {
            width: 75%;

            &.wide {
                width: 92%;
            }
        }

        &-point {
            border-top: 1px solid $color-text-light;

            li {
                counter-increment: number 1;
                padding: 45px 0 30px;
                border-bottom: 1px solid $color-text-light;

                @include view-at(sp) {
                    padding: 25px 0;
                }
                
                p.text {
                    position: relative;
                    color: $color-green-dark;
                    font-size: rem(32px);
                    line-height: 1.6;
                    letter-spacing: 0;
                    padding-left: 42px;
                    
                    @include view-at(sp) {
                        font-size: rem(18px);
                        letter-spacing: 0.04em;
                        padding-left: 25px;
                    }

                    &::before {
                        content: counter(number) "";
                        position: absolute;
                        top: 10px;
                        left: 0;
                        width: 34px;
                        height: 34px;
                        color: $color-white;
                        font-size: 0.9em;
                        line-height: 1;
                        text-align: center;
                        padding-top: 1px;
                        background-color: $color-green-dark;
                        border-radius: 50%;
        
                        @include view-at(sp) {
                            top: 5px;
                            width: 20px;
                            height: 20px;
                        }
                    }
                }

            }
        }
    }
}