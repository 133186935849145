/* --------------------------
テキスト装飾
-------------------------- */

.u-text-color {
    color: $color-text;
}

.u-text-accent {
    color: $color-green;
}

.u-text-important {
    color: $color-red;
}

.u-text-inverse {
    color: $color-white;
}

.u-text-bold {
    font-weight: $bold;
}

.u-text-normal {
    font-weight: $normal;
}

.u-text-sub {
    color: $color-gray;
}

.u-text-light {
    color: $color-text-light;
}

.u-text-red {
    color: $color-red;
}
.u-text-green {
    color: $color-green;
}
.u-text-green-dark {
    color: $color-green-dark;
}

.u-text-note {
    font-size: rem(12px);
    line-height: 1.5;
    letter-spacing: 0.01em;
    text-align: justify;
    text-justify: inter-ideograph;

    @include view-at(sp) {
        font-size: rem(10px);
    }
}

.u-text-lh {
    &-30 {
        line-height: 3;

        @include view-at(sp) {
            line-height: 2;
        }
    }
    &-25 {
        line-height: 2.5;

        @include view-at(sp) {
            line-height: 2;
        }
    }
    &-15 {
        line-height: 1.5;

        @include view-at(sp) {
            &--sp {
                line-height: 1.5;
            }
        }
    }
    &-10 {
        line-height: 1;

        @include view-at(sp) {
            &--sp {
                line-height: 1;
            }
        }
    }
}
.u-text-lts {
    &-012 {
        letter-spacing: 0.12em;
    }
    &-008 {
        letter-spacing: 0.08em;

        @include view-at(sp) {
            &--sp {
                letter-spacing: 0.08em;
            }
        }
    }
    &-004 {
        letter-spacing: 0.04em;
    }
    &-000 {
        letter-spacing: 0;

        @include view-at(sp) {
            &--sp {
                letter-spacing: 0;
            }
        }
    }
}

.u-text-highlight {
    background: linear-gradient(transparent 0, transparent 60%, $color-bg-green-snow 60%, $color-bg-green-snow 100%);
}

.u-text-underline {
    text-decoration: underline;
}

.u-text-x-large {
    font-size: rem(36px);

    @include view-at(sp) {
        font-size: rem(28px);
    }
}

.u-text-large {
    font-size: rem(28px);

    @include view-at(sp) {
        font-size: rem(24px);
    }
}

.u-text-bit-large {
    font-size: rem(24px);

    @include view-at(sp) {
        font-size: rem(18px);
    }
}

.u-text-medium {
    font-size: rem(18px);

    @include view-at(sp) {
        font-size: rem(16px);
    }
}

.u-text-default {
    font-size: rem(16px);

    @include view-at(sp) {
        font-size: rem(14px);
    }
}

.u-text-small {
    font-size: rem(14px);

    @include view-at(sp) {
        font-size: rem(12px);
    }
}

.u-text-x-small {
    font-size: rem(12px);

    @include view-at(sp) {
        font-size: rem(10px);
    }
}