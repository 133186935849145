/* --------------------------
販売中物件
-------------------------- */
.p-property {
    p {
        line-height: 2;
    }

    &-title {
        font-size: rem(30px);
        text-align: center;
        padding-bottom: 10px;
        margin-top: 10px;
        margin-bottom: 0.5em;
        border-bottom: 1px solid $color-text;

        @include view-at(sp) {
            font-size: rem(20px);
        }
    }
    &-detail {
        @include view-at(sp) {
            font-size: rem(12px);
        }
    }
}