/* --------------------------
Gナビ
-------------------------- */

.p-gnav {
    position: fixed;
    top: 63px;
    left: 0;
    width: 100%;
    height: 73px;
    background-color: $color-white;
    z-index: 999;

    @include view-at(laptop) {
        padding: 0 20px;
    }
    @include view-at(tab) {
        display: none;
        top: 48px;
        left: 0;
        right: 0;
        bottom: auto;
        height: auto;
        padding: 10px 0;
        z-index: 1000;
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 1124px;
        max-width: 100%;
        height: 100%;
        margin: 0 auto;

        @include view-at(tab) {
            justify-content: flex-start;
        }

        &-item {
            position: relative;
            text-align: center;

            @include view-at(tab) {
                width: calc(100% / 5);
                padding: 10px 0;
            }
            @include view-at(sp) {
                width: 50%;
                padding: 15px 0 10px;
            }

            &.disabled {
                a {
                    display: inline-block;
                    color: $color-text-snow;
                    pointer-events: none;

                    &:hover {
                        &::before {
                            visibility: hidden;
                            opacity: 0;
                        }
                    }
                }
            }

            &.current {
                a {
                    pointer-events: none;

                    &::before {
                        bottom: -3px;
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }

            span {
                position: absolute;
                top: -15px;
                left: 0;
                right: 0;
                color: $color-white;
                font-size: rem(10px);
                padding: 1px;
                margin: auto;

                @include view-at(tab) {
                    top: 0;
                    font-size: rem(8px);
                }
                @include view-at(sp) {
                    top: 5px;
                }

                &.new {
                    width: 3.5em;
                    background-color: $color-red;
                }
                &.soon {
                    width: 7em;
                    background-color: $color-gray;
                }
            }

            a {
                position: relative;
                display: block;
                color: $color-black;
                font-size: rem(16px);
                line-height: 1.33;
                letter-spacing: 0.04em;

                @include view-at(laptop) {
                    font-size: rem(14px);
                }

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -6px;
                    margin: auto;
                    width: 20px;
                    height: 2px;
                    background-color: $color-green;
                    opacity: 0;
                    visibility: hidden;
                    transition: all .3s;
                }

                &:hover {
                    &::before {
                        bottom: -3px;
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }
    }

    &-tab-menu {
        display: none;

        @include view-at(tab) {
            display: block;

            .button {
                color: $color-white;
                margin: auto;
            }
        }
    }
}
