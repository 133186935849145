/* --------------------------
フッター
-------------------------- */

.p-footer {
    margin: 62px auto 52px;

    @include view-at(tab) {
        margin-bottom: 120px;
    }

    &-property {
        p {
            line-height: 2;
        }

        &-title {
            font-size: rem(30px);
            text-align: center;
            padding-bottom: 10px;
            margin-top: 10px;
            margin-bottom: 0.5em;
            border-bottom: 1px solid $color-text;

            @include view-at(sp) {
                font-size: rem(20px);
            }
        }
        &-detail {
            @include view-at(sp) {
                font-size: rem(12px);
            }
        }
    }

    &-pagetop {
        position: fixed;
        right: 15px;
        bottom: 10px;

        @include view-at(tab) {
            right: 10px;
            bottom: 67px;
        }

        a {
            position: relative;
            display: inline-block;
            width: 30px;
            height: 30px;
            background-color: $color-text;
            border-radius: 3px;

            &::before {
                position: absolute;
                top: 50%;
                left: 50%;
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 5px 10px 5px;
                border-color: transparent transparent $color-white transparent;
                transform: translate(-50%, -50%);
            }
        }
    }

    &-wrapper {
        width: 1024px;
        height: 100%;
        max-width: 100%;
        margin: 0 auto;

        @include view-at(tab) {
            width: 100%;
            padding: 0 15px;
        }
    }

    &-contact {
        text-align: center;
        margin-bottom: 68px;

        @include view-at(sp) {
            margin-bottom: 30px;
        }

        > * {
            color: #111;
        }
        .place {
            font-size: rem(18px);
            line-height: 1;
            text-align: center;
            margin-bottom: 15px;

            @include view-at(sp) {
                font-size: rem(12px);
                margin-bottom: 10px;
            }
        }
        .tel {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-size: rem(58px);
            line-height: 1;
            letter-spacing: 0.08em;
            margin-bottom: 15px;

            @include view-at(sp) {
                font-size: rem(38px);
                margin-bottom: 10px;
            }

            img {
                max-height: 44px;
                margin-right: 10px;

                @include view-at(sp) {
                    max-height: 30px;
                }
            }
        }
        .time {
            line-height: 2;
            text-align: center;

            @include view-at(sp) {
                font-size: rem(11px);
            }
        }

        .notice {
            display: inline-block;
            color: $color-red;
            line-height: 1.6;
            padding: 10px;
            margin: 15px auto 0;
            border-top: 1px solid $color-red;
            border-bottom: 1px solid $color-red;

            .medium {
                font-size: rem(14px);

                @include view-at(sp) {
                    font-size: rem(13px);
                }
            }
            .large {
                font-size: rem(16px);

                @include view-at(sp) {
                    font-size: rem(15px);
                }
            }
        }
    }

    &-note {
        color: $color-red;
        line-height: 2;
        text-align: center;
        margin-top: 20px;
        padding-top: 10px;
        border-top: 1px solid $color-text;

        @include view-at(sp) {
            font-size: rem(12px);
        }

        .large {
            font-size: 150%;
        }
    }

    &-link {
        display: flex;
        justify-content: center;
        align-items: center;

        @include view-at(sp) {
            // display: block;
            flex-direction: column;
        }

        &.company {
            margin-bottom: 48px;

            @include view-at(sp) {
                // width: 55%;
                margin: 0 auto 20px;
            }

            li {
                padding: 0 20px;

                @include view-at(sp) {
                    display: inline-block;
                    text-align: center;
                    padding: 10px 0;
                }

                &.sohgohreal {
                    img {
                        max-height: 44px;
                        margin-top: 5px;
                    }
                }
                &.urbest {
                    img {
                        max-height: 38px;
                        // max-height: 44px;
                        margin-top: 5px;
                    }
                }
                &.hosoda {
                    img {
                        max-height: 52px;
                    }
                }
                a {
                    display: block;
                }
                span {
                    display: block;
                    font-size: rem(12px);
                    text-align: left;
                    padding-bottom: 5px;
                }
            }
        }
        &.expo {
            margin-bottom: 48px;

            @include view-at(sp) {
                margin-bottom: 20px;
            }

            .wrapper {
                max-width: 348px;
                margin: auto;
            }

            p {
                font-size: rem(10px);
                margin-top: 12px;
            }
        }
        &.site {
            margin: 0 -8px;

            @include view-at(sp) {
                margin: -8px auto;
            }

            li {
                padding: 0 8px;

                @include view-at(sp) {
                    padding: 8px 0;
                }

                @include view-at(sp) {
                    text-align: center;
                }

                img {
                    width: 300px;
                }
            }
        }
    }
}