@charset "UTF-8";

$energy-ecology: #4A683F;
$energy-economic: #486D71;
$energy-money: #405D71;
$energy-prevention: #83724B;
$energy-economic-bg: #E5F0F1;
$energy-prevention-bg: #F7F1E5;
$energy-costs-bg: #E2EBF1;

.energy {
    &-point {
        display: flex;
        justify-content: center;
        align-items: center;

        @include view-at(sp) {
            flex-direction: column;
        }

        .line {
            margin: 0 30px;

            @include view-at(sp) {
                margin: 25px auto;
            }

            span {
                width: 55px;
                height: 1px;
                display: block;
                background-color: $color-text-light;

                @include view-at(sp) {
                    width: 30px;
                }

                &:nth-child(1) {
                    transform: rotate(45deg);
                }
                &:nth-child(2) {
                    transform: rotate(-45deg);
                }
            }
        }

        &-text {
            font-size: rem(32px);
            line-height: 1.4;
            text-align: center;
            margin-bottom: 0;

            @include view-at(tab) {
                font-size: rem(28px);
            }

            &.high {
                line-height: 1.75;
            }
            &.small {
                font-size: rem(16px);

                @include view-at(tab) {
                    font-size: rem(14px);
                }
            }
            &.medium {
                font-size: rem(22px);

                @include view-at(tab) {
                    font-size: rem(20px);
                }
            }

            span {
                &.medium {
                    font-size: 131%;
                }
            }
        }
    }

    &-card {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: -15px;

        li {
            width: calc(100% / 3);
            padding: 15px;

            @include view-at(sp) {
                width: 85%;
            }

            &.ecology {
                .lead {
                    color: $energy-ecology;
                }
            }
            &.economic {
                .lead {
                    color: $energy-economic;
                }
            }
            &.money {
                .lead {
                    color: $energy-money;
                }
            }
            &.loan {
                .lead {
                    color: $energy-economic;
                }
            }
            &.prevention {
                .energy-card-inner {
                    @include view-at(pc) {
                        padding-bottom: 26px;
                    }
                }
                .lead {
                    color: $energy-prevention;
                }
            }
        }

        &-inner {
            height: 100%;
            text-align: center;
            padding: 40px 30px 60px;
            box-shadow: 0 3px 16px rgba($color-black, 0.36);

            @include view-at(sp) {
                padding: 20px 15px 25px;
            }

            p {
                letter-spacing: 0.12em;
                text-align: center;
                
                &.lead {
                    font-size: rem(24px);
                    line-height: 1.66;
                    margin: 10px auto 5px;

                    @include view-at(sp) {
                        font-size: rem(18px);
                        line-height: 1.4;
                    }
                }
                &.text {
                    font-size: rem(18px);
                    line-height: 1.875;

                    @include view-at(sp) {
                        font-size: rem(14px);
                        line-height: 1.6;
                    }
                }
            }
        }
    }

    &-subnav {
        display: flex;
        justify-content: center;
        margin: 0 -5px;
        
        @include view-at(sp) {
            flex-wrap: wrap;
            margin: -5px;
        }

        li {
            min-width: 300px;
            max-width: 100%;
            padding: 0 5px;

            @include view-at(sp) {
                min-width: auto;
                width: 100%;
                padding: 5px;
            }

            a {
                display: block;
                color: $color-green-dark;
                font-size: rem(20px);
                line-height: 1.5;
                letter-spacing: 0.04em;
                text-align: center;
                padding: 14px;
                background-color: $color-white;
                border: 1px solid $color-green-dark;

                @include view-at(sp) {
                    font-size: rem(16px);
                    padding: 10px;
                }

                &[aria-selected="true"] {
                    color: $color-white;
                    background-color: $color-green-dark;
                }
                &.high {
                    padding: 29px 14px;

                    @include view-at(sp) {
                        padding: 22px 10px;
                    }
                }
            }
        }

        &-wrapper {
            margin: 150px 0 40px;

            @include view-at(sp) {
                margin: 100px 0 40px;
            }
        }

        &-item {
            padding: 0;
            border: none;
        }
    }

    &-link {
        width: 800px;
        max-width: 100%;
        display: block;
        color: $color-white;
        font-size: rem(20px);
        text-align: center;
        padding: 35px 15px;
        margin: 0 auto 60px;
        background-color: $energy-economic;

        @include view-at(sp) {
            font-size: rem(16px);
            padding: 27px 10px;
            margin-bottom: 40px;
        }
    }

    &-bg {
        padding: 40px 40px 60px;

        @include view-at(sp) {
            padding: 20px 20px 30px;
        }

        &-economic {
            background-color: $energy-economic-bg;
        }
        &-prevention {
            background-color: $energy-prevention-bg;
        }
        &-costs {
            background-color: $energy-costs-bg;
        }
    }

    &-icon {
        max-height: 80px;

        @include view-at(sp) {
            max-height: 50px;
        }

        &-zeh {
            max-height: 94px;
        }
    }
    &-h2 {
        font-size: rem(36px);

        @include view-at(sp) {
            font-size: rem(28px);
            line-height: 1.4;
        }
    }
    &-h3 {
        font-size: rem(36px);
        padding-bottom: 10px;
        margin-bottom: 40px;
        border-bottom: 1px solid $color-green;

        @include view-at(sp) {
            font-size: rem(28px);
            margin-bottom: 20px;
        }

        .large {
            font-size: 155%;
        }
        .small {
            font-size: 66%;
        }
    }
    &-lead {
        font-size: rem(32px);
        line-height: 1.7;
        text-align: center;

        @include view-at(sp) {
            font-size: rem(24px);
        }

        &.economic {
            color: $energy-economic;
        }
        &.prevention {
            color: $energy-prevention;
        }
    }
    &-note {
        font-size: rem(12px);
        line-height: 1.25;
        letter-spacing: 0.01em;

        @include view-at(sp) {
            font-size: rem(10px);
        }
    }

    &-sun {
        &-row {
            display: flex;
            margin: -15px;

            @include view-at(sp) {
                flex-wrap: wrap;
                margin: -10px;
            }
        }

        &-col {
            padding: 15px;

            @include view-at(sp) {
                width: 100% !important;
                padding: 10px;
            }

            &-image {
                width: 54%;
            }
            &-detail {
                width: 46%;
            }
        }
    }

    &-zeh {
        padding: 40px 60px 60px;
        border: 1px solid #005F5D;

        @include view-at(sp) {
            padding: 20px 30px 30px;
        }

        &-question {
            display: flex;
            flex-wrap: wrap;
            margin: -8px;

            li {
                padding: 8px;
            }

            .arrow {
                width: 8%;
                padding-bottom: 160px;

                @include view-at(sp) {
                    width: 100%;
                    text-align: center;
                    padding-bottom: 8px;
                }

                img {
                    @include view-at(sp) {
                        width: 50px;
                        transform: rotate(90deg);
                    }
                }
            }
            .left {
                width: 23.1%;

                @include view-at(sp) {
                    width: 55%;
                }
            }
            .center {
                width: 37.8%;

                @include view-at(sp) {
                    width: 90%;
                }
            }
            .right {
                width: 23.1%;

                @include view-at(sp) {
                    width: 55%;
                }
            }
        }
    }

    &-eco {
        &-detail {
            width: 52.8%;
            padding: 20px;

            @include view-at(sp) {
                width: 100%;
                padding: 10px;
            }
        }
        &-image {
            width: 47.2%;
            padding: 20px;

            @include view-at(sp) {
                width: 100%;
                padding: 10px;
            }
        }
    }

    &-title {
        display: flex;
        align-items: center;

        &-icon {
            flex-shrink: 0;
            margin-right: 20px;

            @include view-at(sp) {
                margin-right: 10px;
            }
        }
        &-text {
            color: $energy-economic;
            font-size: rem(24px);
            line-height: 1.5;
            letter-spacing: 0.12em;

            @include view-at(sp) {
                font-size: rem(18px);
            }

            &.low {
                line-height: 1.1;
            }
            &.small {
                font-size: rem(20px);

                @include view-at(sp) {
                    font-size: rem(14px);
                }
            }
            &.prevention {
                color: $energy-prevention;
                line-height: 1.85;
                letter-spacing: 0;
            }
            .number {
                color: $color-red-light;
                font-size: 175%;
            }
        }
    }

    &-housing {
        &-grade {
            width: 800px;
            max-width: 100%;
            margin: 0 auto 60px;

            @include view-at(sp) {
                margin-bottom: 40px;
            }
        }

        &-wrapper {
            width: 950px;
            max-width: 100%;
            margin: auto;
        }

        &-title {
            font-size: rem(22px);
            line-height: 1.36;
            text-align: center;
            padding: 15px 10px;
            margin-bottom: 20px;
            background-color: $color-bg-green-snow;

            @include view-at(sp) {
                font-size: rem(18px);
                margin-bottom: 10px;
            }

            &.low {
                padding: 30px 10px;

                @include view-at(sp) {
                    padding: 27px 10px;
                }
            }
        }

        &-col {
            padding: 20px;

            @include view-at(sp) {
                width: 100% !important;
                padding: 10px;
            }

            &-text {
                width: 41%;
            }
            &-image {
                width: 59%;
            }
        }
    }

    &-summary {
        &-title {
            color: $color-white;
            font-size: rem(24px);
            line-height: 1.6;
            text-align: center;
            padding-bottom: 4px;
            background-color: $color-green;

            @include view-at(sp) {
                font-size: rem(18px);
            }

            .number {
                font-size: 146%;
            }
        }

        &-list {
            li {
                position: relative;
                width: 45%;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                line-height: 1;
                letter-spacing: 0.04em;
                z-index: 1;

                @include view-at(sp) {
                    width: 100%;
                }

                &::before {
                    content: '';
                    position: absolute;
                    left: 0.3em;
                    bottom: 0.5em;
                    width: calc(100% - 0.3em);
                    height: 2px;
                    background-image : linear-gradient(to right, $color-text 2px, transparent 2px);
                    background-size: 20px 2px;
                    background-repeat: repeat-x;
                    background-position: left bottom;
                    z-index: -1;

                    @include view-at(sp) {
                        left: 0;
                        background-size: 15px 2px;
                    }
                }

                .name {
                    font-size: rem(18px);
                    background-color: $color-white;

                    @include view-at(sp) {
                        font-size: rem(14px);
                    }
                }
                .red { color: $color-red; }
                .number {
                    color: $color-red;
                    font-size: rem(38px);
                    background-color: $color-white;

                    @include view-at(sp) {
                        font-size: rem(30px);
                    }
                }
            }
        }
    }

    &-costs {
        padding: 130px 150px 100px;
        margin-top: 165px;

        @include view-at(sp) {
            padding: 60px 25px;
            margin-top: 100px;
        }

        &-white {
            position: relative;
            padding: 60px 50px 30px;

            @include view-at(sp) {
                padding: 30px 20px 20px;
            }
        }
        &-icon {
            position: absolute;
            top: -40px;
            left: 0;
            right: 0;
            text-align: center;

            @include view-at(sp) {
                top: -25px;
            }
        }
        &-text {
            &.inline {
                display: inline-block;
            }

            .red {
                color: $color-red;
            }
            .large {
                font-size: rem(36px);

                @include view-at(sp) {
                    font-size: rem(28px);
                }
            }
            .medium {
                font-size: rem(20px);

                @include view-at(sp) {
                    font-size: rem(16px);
                }
            }
            .small {
                font-size: rem(14px);

                @include view-at(sp) {
                    font-size: rem(12px);
                }
            }
            .number {
                font-size: rem(46px);

                @include view-at(sp) {
                    font-size: rem(32px);
                }
            }
            .point {
                &.u-bg-gray {
                    padding: 2px 10px;
                    box-shadow: 0 0 5px rgba($color-black, 0.25);
                }
                &.u-bg-red {
                    padding: 3px 50px;
                    box-shadow: 0 0 5px rgba($color-black, 0.25);
                }
            }
        }
    }

    &-measure {
        &-wrapper {
            width: 500px;
            max-width: 100%;
            margin: auto;
        }

        &-title {
            @include center-line($padding: 20px);
            max-width: 380px;
            font-size: rem(24px);
            margin: 25px auto;

            @include view-at(sp) {
                font-size: rem(18px);
                margin-bottom: 15px;
            }
        }

        &-list {
            display: flex;
            flex-wrap: wrap;
            margin: -10px;

            @include view-at(sp) {
                margin: -5px;
            }

            li {
                width: 50%;
                padding: 10px;

                @include view-at(sp) {
                    width: 100%;
                    padding: 5px;
                }

                .inner {
                    border: 1px solid $energy-economic;
                }
                .title {
                    color: $color-white;
                    font-size: rem(22px);
                    line-height: 1;
                    letter-spacing: 0.05em;
                    text-align: center;
                    padding: 9px 5px;
                    margin-bottom: 0;
                    background-color: $energy-economic;

                    @include view-at(sp) {
                        font-size: rem(18px);
                    }
                }
                .text {
                    font-size: rem(20px);
                    line-height: 1;
                    letter-spacing: 0.05em;
                    text-align: center;
                    padding: 9px 5px;
                    background-color: $color-white;

                    @include view-at(sp) {
                        font-size: rem(16px);
                    }

                    .red {
                        color: $color-red;
                    }
                    .small {
                        font-size: 60%;
                    }
                    .medium {
                        font-size: 130%;
                    }
                }
            }
        }
    }

    &-lease {
        padding: 15px;
        background-color: $color-bg-green-snow;

        p {
            text-align: center;

            &.lead {
                color: $color-green;
                font-size: rem(20px);
                line-height: 1.5;
                margin-bottom: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid $color-green;
            }
            &.detail {
                line-height: 1.75;

                @include view-at(sp) {
                    font-size: rem(14px);
                }
            }
        }
    }
}