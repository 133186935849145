.p-cta {
    display: block;
    width: 800px;
    max-width: 100%;
    margin: auto;
    border: 1px solid $color-red;

    @include view-at(sp) {
        width: 100%;
    }

    span {
        &.head {
            display: block;
            color: $color-white;
            font-size: rem(32px);
            text-align: center;
            padding: 18px 10px;
            background-color: $color-red;

            @include view-at(sp) {
                font-size: rem(24px);
                line-height: 1.2;
                padding: 10px;
            }
        }
        &.text {
            display: block;
            color: $color-text-light;
            font-size: rem(18px);
            line-height: 2;
            text-align: center;
            padding: 27px 10px;

            @include view-at(sp) {
                font-size: rem(14px);
                line-height: 1.6;
                padding: 20px 10px;
            }

            &.large {
                font-size: rem(32px);
                line-height: 1;
                padding: 20px 10px;

                @include view-at(sp) {
                    font-size: rem(24px);
                    line-height: 1.2;
                    padding: 12px 10px;
                }
            }
        }
    }
}