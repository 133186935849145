@charset "UTF-8";

.town {
    &-row {
        margin: 0 -25px !important;

        @include view-at(tab) {
            margin: 0 -20px !important;
        }
        @include view-at(sp) {
            margin: 0 -10px !important;
        }
    }
    &-col {
        padding: 0 25px !important;

        @include view-at(tab) {
            padding: 0 20px !important;
        }
        @include view-at(sp) {
            padding: 0 10px !important;
        }

        &-large {
            width: 52.5%;

            @include view-at(sp) {
                width: 100%;
            }
        }
        &-small {
            width: 47.5%;

            @include view-at(sp) {
                width: 100%;
            }
        }
    }

    &-h3 {
        &--border {
            line-height: 1.4;
            padding-bottom: 15px;
            margin-bottom: 10px;
            border-bottom: 1px solid $color-text-light;

            @include view-at(sp) {
                padding-bottom: 8px;
                margin-bottom: 5px;
            }
        }
    }
    &-text {
        font-size: rem(14px);
        line-height: 2.5;

        @include view-at(sp) {
            font-size: rem(12px);
            line-height: 2;
        }
    }

    &-wrapper {
        width: 800px;
        max-width: 100%;
        margin: auto;
    }

    &-mb {
        &200 {
            margin-bottom: 200px;

            @include view-at(sp) {
                margin-bottom: 100px;
            }
        }
    }

    &-color {
        &-title {
            color: $color-white;
            padding: 12px 10px;
            margin-bottom: 35px;

            @include view-at(sp) {
                padding: 10px 5px;
                margin-bottom: 20px;
            }

            &.elegant { background-color: #805A78; }
            &.noble { background-color: #9B8356; }
            &.imprressive {background-color: #536F7A; }

            p {
                line-height: 1;
                text-align: center;
                
                &.en {
                    font-size: rem(14px);
                    margin-bottom: 5px;

                    @include view-at(sp) {
                        font-size: rem(12px);
                    }
                }
                &.ja {
                    font-size: rem(24px);

                    @include view-at(sp) {
                        font-size: rem(18px);
                    }
                }
            }
        }
    }
}