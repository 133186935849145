@charset "UTF-8";

.map {
    &-plot {
        &-detail {
            line-height: 1.4;
            text-indent: -1.2em;
            padding-left: 1.2em;

            &::first-letter {
                font-size: rem(18px);
            }
        }
    }
}