@charset "UTF-8";

$info-shop: #952529;
$info-park: #7BA918;
$info-education: #BF7700;
$info-medical: #005A9F;
$info-sports: #009D95;
$info-finance: #706167;

.location {
    &-subnav {
        display: flex;
        justify-content: center;
        margin: 0 -10px;

        @include view-at(sp) {
            margin: 0 -5px;
        }

        li {
            min-width: 260px;
            max-width: 100%;
            padding: 0 10px;

            @include view-at(sp) {
                min-width: auto;
                width: 50%;
                padding: 0 5px;
            }

            a {
                display: block;
                color: $color-green-dark;
                font-size: rem(20px);
                line-height: 1.5;
                letter-spacing: 0.04em;
                text-align: center;
                padding: 14px 10px;
                background-color: $color-white;
                border: 1px solid $color-green-dark;

                @include view-at(sp) {
                    font-size: rem(14px);
                    padding: 8px 2px;
                }

                &[aria-selected="true"] {
                    color: $color-white;
                    background-color: $color-green-dark;
                }

                &.high {
                    padding: 29px 14px;

                    @include view-at(sp) {
                        padding: 19px 2px;
                    }
                }
            }
        }

        &-item {
            padding: 0;
            border: none;
        }
    }

    &-row {
        margin: -10px !important;
    }
    &-col {
        padding: 10px !important;
    }

    &-pickup {
        &-title {
            color: $color-green-dark;
            font-size: rem(38px);
            line-height: 1;
            letter-spacing: 0.08em;
            text-align: center;
            margin-bottom: 15px;
        }
    }

    &-shortly {
        width: 800px;
        margin: 50px auto 100px;
        padding: 25px 65px 45px;
        border: 1px solid $color-green;

        @include view-at(sp) {
            width: 100%;
            margin: 30px auto 80px;
            padding: 15px 25px;
        }

        &-list {
            display: flex;
            padding: 15px 0;
            border-bottom: 1px dotted $color-gray-dark;

            @include view-at(sp) {
                flex-wrap: wrap;
                padding: 10px 0;
            }

            li {
                width: 50%;
                display: flex;
                align-items: center;

                @include view-at(sp) {
                    width: 100%;
                }

                &:nth-child(1) {
                    @include view-at(sp) {
                        padding-bottom: 10px;
                    }
                }
                &:nth-child(2) {
                    padding-left: 20px;
                    border-left: 1px dotted $color-gray-dark;

                    @include view-at(sp) {
                        padding-top: 10px;
                        padding-left: 0;
                        border-left: none;
                        border-top: 1px dotted $color-gray-dark;
                    }
                }

                &.single {
                    padding: 5px 0 5px 40px;
                }
                &.wide {
                    width: 100%;
                }

                .pict {
                    margin-right: 10px;

                    img {
                        max-width: 35px;
                    }
                }
                .text {
                    font-size: rem(13px);
                    line-height: 1.4;
                    letter-spacing: 0;

                    .number {
                        font-size: 184%;
                    }
                }
            }
        }
    }

    &-roadside {
        width: 670px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: auto;

        @include view-at(sp) {
            width: 100%;
            border-top: 1px dotted $color-gray-dark;
            border-bottom: 1px dotted $color-gray-dark;

            &.border-none {
                border-top: none;
            }
        }

        &-pict {
            width: 100%;
            text-align: center;

            @include view-at(sp) {
                width: 45px;
                text-align: left;
                padding: 5px 0;
            }

            img {
                max-width: 35px;
            }
        }
        &-list {
            display: flex;
            padding: 15px 0;
            border-bottom: 1px dotted $color-gray-dark;

            @include view-at(sp) {
                flex-wrap: wrap;
                padding: 0;
            }

            &.border-none {
                @include view-at(sp) {
                    border: none;
                }
            }

            &-wrapper {
                width: 100%;

                @include view-at(sp) {
                    width: calc(100% - 45px);
                }
            }

            li {
                width: 50%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding: 5px 0 5px 40px;

                @include view-at(sp) {
                    width: 100%;
                    padding: 10px 0;
                }

                &:nth-child(2) {
                    border-left: 1px dotted $color-gray-dark;

                    @include view-at(sp) {
                        border-left: none;
                        border-top: 1px dotted $color-gray-dark;
                    }
                }
                &.wide {
                    width: 100%;
                    justify-content: center;
                    padding: 5px;

                    @include view-at(sp) {
                        justify-content: flex-start;
                        padding: 10px 0;
                    }
                }

                .text {
                    font-size: rem(13px);
                    line-height: 1.4;
                    letter-spacing: 0;

                    @include view-at(sp) {
                        font-size: rem(15px);
                    }

                    .number {
                        font-size: 184%;
                    }
                }
            }
        }
        &-inner {
            @include view-at(sp) {
                border-top: 1px dotted $color-gray-dark;
                border-bottom: 1px dotted $color-gray-dark;
            }
        }
    }

    &-detail {
        &-title {
            color: $color-bg-green-snow;
            font-size: rem(72px);
            line-height: 1;
            letter-spacing: 0.12em;
            text-align: center;
            margin-bottom: -5px;
            padding-top: 100px;

            @include view-at(sp) {
                font-size: rem(42px);
                letter-spacing: 0;
                margin-bottom: -3px;
                padding-top: 70px;
            }
        }
    }

    &-hidden {
        overflow-x: hidden;
    }

    &-panel {
        margin-top: 280px;

        @include view-at(sp) {
            margin-top: 100px;
        }

        &-title {
            position: relative;
            font-size: rem(38px);
            line-height: 1.4;
            letter-spacing: 0.08em;
            text-align: center;
            z-index: 1;

            @include view-at(sp) {
                font-size: rem(28px);
            }

            &.sagami { color: #54775B; }
            &.rinkan { color: #507980; }
            &.minami { color: #8F7B53; }
        }

        &-row {
            display: flex;
            margin: -23px;

            @include view-at(sp) {
                display: block;
                margin: 0 auto;
            }
        }
        &-col {
            &-image {
                width: 52.5%;
                padding: 23px;

                @include view-at(sp) {
                    width: 100%;
                    padding: 0;
                }

                .bg {
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        width: 500px;
                        height: 500px;

                        @include view-at(sp) {
                            top: -100px;
                            bottom: auto;
                            width: 50%;
                            height: 600px;
                        }
                    }

                    + p {
                        position: relative;
                    }
                    
                    &.sagami {
                        @include view-at(sp) {
                            padding-right: 8%;
                        }

                        &::before {
                            background-color: rgba(#8CBC92, 0.4);
                            transform: translateX(710px);

                            @include view-at(sp) {
                                transform: translateX(0);
                            }
                        }
                    }
                    &.rinkan {
                        @include view-at(sp) {
                            padding-left: 8%;

                            + p {
                                padding-left: 8%;
                            }
                        }

                        &::before {
                            background-color: rgba(#87C0CA, 0.4);
                            transform: translateX(-710px);

                            @include view-at(sp) {
                                left: 0;
                                transform: translateX(0);
                            }
                        }
                    }
                    &.minami {
                        @include view-at(sp) {
                            padding-right: 8%;
                        }

                        &::before {
                            background-color: rgba(#E8C689, 0.4);
                            transform: translateX(710px);

                            @include view-at(sp) {
                                top: -140px;
                                transform: translateX(0);
                            }
                        }
                    }

                    img {
                        position: relative;
                    }
                }
            }
            &-detail {
                position: relative;
                width: 47.5%;
                padding: 23px;

                @include view-at(sp) {
                    width: 100%;
                    padding: 0;
                }
            }
        }
    }

    &-tab {
        &-nav {
            margin-bottom: 30px;

            @include view-at(sp) {
                margin: 0 0 10px;
            }

            &-item {
                width: calc(100% / 6);
                border: none;
                border-left: 1px solid $color-text;

                @include view-at(sp) {
                    width: calc(100% / 3);
                    padding: 0;
                    margin: 5px 0;

                    &:nth-child(3) {
                        border-right: 1px solid $color-text;
                    }

                    &:nth-child(1),
                    &:nth-child(2),
                    &:nth-child(3) {
                        a {
                            &::before {
                                content: '';
                                position: absolute;
                                left: 4px;
                                bottom: -5px;
                                display: inline-block;
                                width: calc(100% - 8px);
                                height: 1px;
                                background-color: $color-text;
                            }
                        }
                    }
                }

                &:last-child {
                    border-right: 1px solid $color-text;

                    a {
                        border-right: none;
                    }
                }

                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: calc(100% - 15px);
                    height: 100%;
                    font-weight: $regular;
                    line-height: 1.47;
                    padding: 13px 5px;
                    margin: auto;
                    border: none;

                    @include view-at(sp) {
                        position: relative;
                        width: calc(100% - 10px);
                        font-size: rem(11px);
                        padding: 5px 2px;
                    }

                    &[aria-selected="true"] {
                        color: $color-white;
                        border-top: none;
                    }
                }

                &.shop {
                    a[aria-selected="true"] {
                        background-color: $info-shop;
                    }
                }
                &.park {
                    a[aria-selected="true"] {
                        background-color: $info-park;
                    }
                }
                &.education {
                    a[aria-selected="true"] {
                        background-color: $info-education;
                    }
                }
                &.medical {
                    a[aria-selected="true"] {
                        background-color: $info-medical;
                    }
                }
                &.sports {
                    a[aria-selected="true"] {
                        background-color: $info-sports;
                    }
                }
                &.finance {
                    a[aria-selected="true"] {
                        background-color: $info-finance;
                    }
                }
            }
        }

        &-contents {
            padding: 0 0 20px;
            border: none;

            @include view-at(sp) {
                padding-bottom: 0;
            }
        }
    }

    &-info {
        margin-top: 265px;

        @include view-at(sp) {
            margin-top: 130px;
        }

        &-wrapper {
            counter-reset: number;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            height: 600px;

            @include view-at(sp) {
                display: block;
                height: auto;
            }
        }

        &-map {
            width: 62%;

            @include view-at(sp) {
                width: 100%;
            }

            &-area {
                height: 600px;

                @include view-at(sp) {
                    height: auto;
                    padding-top: 75%;
                }
            }
        }

        &-detail {
            width: 38%;
            height: 600px;
            padding: 0 20px;
            background-color: $color-white;
            overflow-y: scroll;
            z-index: 0;

            @include view-at(sp) {
                width: 100%;
                // height: 210px;
                height: 230px;
                padding: 0 10px;
            }
        }

        &-item {
            position: relative;
            counter-increment: number 1;
            display: flex;
            flex-wrap: nowrap;
            padding: 10px 0;
            border-bottom: 1px solid $color-text-snow;

            @include view-at(sp) {
                padding: 8px 0;
            }

            &::before {
                content: counter(number) "";
                position: absolute;
                top: 10px;
                left: 0;
                display: inline-block;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 22px;
                height: 22px;
                color: $color-white;
                font-size: rem(16px);
                line-height: 1;
                text-align: center;
                padding: 3px 0;
                margin-right: 10px;
                border-radius: 12px;

                @include view-at(sp) {
                    top: 8px;
                    width: 18px;
                    height: 18px;
                    font-size: 12px;
                    padding: 0;
                }
            }

            &:not(:first-of-type) {
                margin-top: 10px;
            }
            &:last-child {
                border-bottom: none;
            }

            a {
                width: 100%;
                color: $color-text;
                padding-left: 35px;

                @include view-at(sp) {
                    // display: flex;
                    // flex-wrap: nowrap;
                    // justify-content: space-between;
                    // align-items: flex-end;
                    padding-left: 25px;
                }
            }

            .name,
            .min {
                font-size: rem(14px);
                line-height: 1.6;
                margin-bottom: 0;

                @include view-at(sp) {
                    font-size: rem(10px);
                }
            }
            .min {
                flex-shrink: 0;
                text-align: right;
            }

            &.shop {
                &::before {
                    background-color: $info-shop;
                }
            }
            &.park {
                &::before {
                    background-color: $info-park;
                }
            }
            &.education {
                &::before {
                    background-color: $info-education;
                }
            }
            &.medical {
                &::before {
                    background-color: $info-medical;
                }
            }
            &.sports {
                &::before {
                    background-color: $info-sports;
                }
            }
            &.finance {
                &::before {
                    background-color: $info-finance;
                }
            }
        }
    }
}