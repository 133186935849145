@charset "UTF-8";

.access {
    &-min {
        display: flex;
        flex-wrap: wrap;
        margin: -20px -25px;

        @include view-at(tab) {
            margin: -20px;
        }
        @include view-at(sp) {
            margin: -15px -10px;
        }

        li {
            width: calc(100% / 3);
            padding: 20px 25px;

            @include view-at(tab) {
                padding: 20px;
            }
            @include view-at(sp) {
                width: 100%;
                padding: 15px 10px;
            }
        }

        &-title {
            line-height: 1.28;
        }
    }

    &-station {
        &-bg {
            background-color: $color-bg-green-snow;
        }

        &-title {
            color: $color-white;
            padding: 28px 20px;
            background-color: $color-green;

            @include view-at(sp) {
                padding: 21px 10px;
            }
        }

        &-sagami {
            @include view-at(sp) {
                max-width: 315px;
            }
        }
        &-rinkan {
            @include view-at(sp) {
                max-width: 210px;
            }
        }
    }

    &-car {
        &-title {
            font-size: rem(38px);

            @include view-at(sp) {
                font-size: rem(26px);
            }
        }
    }
}